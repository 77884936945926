import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import Footer from './footer'

const Contact = () => {
  return (
    <div>
       <div className='heading'>
       <p className='allow'>CONTACT US</p>
        </div>
        <div className='boolean'>
        <div className='form-container'>
        <div className='width'>
            <div className='con'>
            {/* <div className='form-heading'>
        <p className='removed'> Contact Us</p>
        </div> */}
        <form action=''>
            <div className='total-container'>
            <div className='name'>
                <lable className='lable'>Name</lable>
            <input className='whole'type='text' />
            <Icon icon="wpf:name" />
            </div>
            <div className='name'>
                <lable className='lable'>Email</lable>
                <input className='whole'type='text'/>
                <Icon icon="mdi:email-outline" />
            </div>
    
            <div className='name'>
                <lable className='lable'>Phone</lable>
                <input className='whole' type='no'/>
                <Icon icon="ph:phone-bold" />
            </div>
            <div className='name'>
                <lable className='lable'>Service</lable>
                <input className='whole' type='text'/>
                <Icon icon="openmoji:department-store" />
            </div>
            <div >
            <textarea className='msg-new' rows='6' cols='50' name='message' placeholder='Your message here'></textarea>
            </div>
            <div className='send'>
            <button  className='video'>Send</button>
            </div>
            </div>
            
        </form>
        </div>
        </div>
        </div>
        </div>

            </div>



  )
}

export default Contact