import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import Slider from './slider';
import { Slide } from 'pure-react-carousel';
import Footer from './footer';
import { useState } from 'react';

const Service = () => {
    const [mobileNumber, setMobileNumber] = useState('');


    const handleChange = (event) => {
        const { value } = event.target;
        // Ensure only numeric characters are allowed
        const numericValue = value.replace(/\D/g, '');
        // Limit to 10 digits
        const trimmedValue = numericValue.slice(0, 10);
        // Update state with the cleaned-up value
        setMobileNumber(trimmedValue);
      };

  const displayNum = mobileNumber.slice(0,10)
    
return (
    
    <div>
        <Slider/>
        {/* <div className='heading'>
       <h1><span className='branch'>CONTACT US</span></h1>
        </div>
        <div className='form-container'>
        <div className='width'>
            <div  style={{width:'700px'}} >
            <img/>
            </div>
            <div>
            <div className='form-heading'>
        <h4 ><span className='branch'>You Don't Hesitate To Contact With Us, Now <span className='source'>Say Hello......</span></span></h4>
        </div>
        <form action=''>
            <div className='total-container'>
            <div className='name'>
            <input className='whole'type='text' placeholder='Name' maxlength='14'/>
            <Icon icon="wpf:name" />
            </div>
            <div className='name'>
                <input className='whole'type='text'placeholder='Email'maxlength='27'/>
                <Icon icon="mdi:email-outline" />
            </div>
    
            <div className='name'>
            <input
      id='mobilenumber'
      name='mobilenumber'
      value={mobileNumber}
      onChange={handleChange}
      className='whole'
      type='tel'
      placeholder='Phone Number'
      maxLength={10}
    />                <Icon icon="ph:phone-bold" />
            </div>
            <div className='name'>
                <input className='whole' type='text' placeholder='department'/>
                <Icon icon="openmoji:department-store" />
            </div>
            <div className='msg'>
            <textarea id='text' rows='7' cols='50' name='message' placeholder='message'></textarea>
            </div>
            </div>
            <button className='video'>Send</button>
        </form>
        </div>
        </div>
         */}
        </div>
        
           
           
           
           


        
        
        
        
 )
}
            

export default Service