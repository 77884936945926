import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Home from './home';
import Service from './service';
import Tech from './tech';
import About from './about';
import Contact from './contact';
import Footer from './footer';
import Aboutpage from './aboutpage';
import Servicepage from './servicepage';
import Add from './add';
import Serdetail from './serdetail';
import Conpage from './conpage';
import Face from './face';

const App =()=>{
  const items=[
    {
      id: 1,
      Name: 'IT Consultancy',
      img: 'water.jpg',
      heading: 'IT Consultancy',
      content: 'Revamp Your Business with Smart IT Strategies from Sade Tech.',
      page: 'Revamp Your Business with Smart IT Strategies from Sade Tech.',
      points: [
        {
          id: 1,
          head: '- Sade Tech is the go-to company for strategy consulting services that are tailored to your needs.'
        },
        {
          id: 2,
          head: '- Our team of experts will partner with you to create IT strategies and consulting services that will ensure a fast-paced business revamp.'
        },
        {
          id: 3,
          head: '- Our intelligent execution strategy will help you achieve your goals quickly and efficiently.'
        }
      ],
      parahead:'Approach to Consulting',
      approch:[
        {id:1,
          head:`Our consulting approach is unique and sets businesses apart from their competition. We aim to modernize legacy systems and create
        breakthroughs, which enables enterprises to integrate value-driven, customer-focused, and futuristic solutions that optimize costs and
increase customer satisfaction.`
        },
        {
          id:2,
          head:`At Sade Tech, we have years of industry experience and can help propel your business by discovering scaling opportunities. We
specialize in everything from IT infrastructure and software to people, processes, and customer service. By understanding gaps and
areas for improvement, we empower you to tap into untapped business potential.`
        },
        {
          id:3,
          head:`Our consulting services cover everything from planning strategies to automating implementation. Trust Sade Tech to be your go-to IT
consulting partner, and we will guide you every step of the way`
        }
]
    },    
    {
      id:2,
      Name:'QA & Testing',
      img:'water.jpg',
      heading:'QA & Testing',
      content:'Our Sade techno services are designed to ensure that software and business projects attain superior standards of performance,reliability, and user satisfaction.',
     test:[{
      id:1,
      head:`Our Sade techno services are designed to ensure that software and business projects attain superior standards of performance,
reliability, and user satisfaction. We provide businesses with expert guidance and support to enable them to enhance the
quality of their projects. With our services, businesses can rest assured that their projects will meet the highest standards and
deliver an exceptional user experience.`},
{   id:2,
  head:`Our team collaborates extensively with stakeholders in order to comprehensively comprehend the requisites, scope, and
benchmarks for success of the project. Our team then proceeds to appraise the current QA procedures and determine areas for
enhancement or rectify any deficiencies, resulting in a custom-designed QA strategy.`
},
{
  id:3,
  head:`By availing our top-notch QA services, businesses can enhance the quality of their products, augment customer satisfaction,
and attain a competitive edge in the ever-changing market scenario`
}
]
},    
    {
      id:3,
      Name:'UX/UI Design',
      img:'water.jpg',
      heading:'UX/UI Design',
      content:'Elevate your software products with cutting-edge UI/UX design services that enhance user engagement and boost revenue.',
      design:[
        {
         id:1,
         head:`Elevate your software products with cutting-edge UI/UX design services that enhance user engagement and
boost revenue. Our skilled team at a leading UI/UX Design Company delivers stunning designs that drive growth and
maximize customer satisfaction.`
        },
        {
          id:2,
          head:`Maximize your potential with our specialized UI UX design services. Our team is dedicated to delivering
innovative and scalable interfaces that are both agile and value-driven. Our enterprise-grade code quality is supported
by Microsoft-endorsed design and development lifecycles, ensuring a state-of-the-art feel and enhanced user
experience. Trust in our expertise to elevate your project to the next level.`
        }
      ],
      designhead:'Essence of our UI/ UX Design:',
      uxui:[
        {id:1,
          head:`As a UI/UX development company, Sade Techno Services endeavors to establish a robust business identity for its
clients through its exceptional design services. The company's objective is to analyze the client's business persona and
align their business goals by delivering robust UI/UX design and development services.`
    },
    {id:2,
      head:`By utilizing our bespoke product development process and implementing superior code quality, we are able to align
design elements with the unique business needs of our clients, while minimizing potential risks. Our top-tier designs
also ensure optimal user activation and business conversions.`
    }]
},
    {id:4,
      Name:'Mobile App Development',
      img:'water.jpg',
      heading:'Mobile App Development',
      content:'Explore the ever-evolving realm of mobile technology through Sade Techno Mobile App Development Services.',
      page:`Explore the ever-evolving realm of mobile technology through Sade Techno's Mobile App
Development Services. Our expertise lies in creating bespoke mobile applications that deliver a seamless user
experience coupled with powerful functionality. Irrespective of the platform, be it Android or iPhone, we focus
on bringing your ideas to life and helping your application stand out amidst the stiff competition in the app
marketplace.`},
    {id:5,
      Name:'Web Application Development',
      img:'water.jpg',
      heading:'Web Application Development',
      content:'At Sade techno solutions, we help companies evolve into dynamic, forward-thinking organizations that thrivein an ever-changing landscape.',
     webhead:`Supporting business transformation through web development`,
     application:[
      {
        id:1,
        head:`At Sade techno solutions, we help companies evolve into dynamic, forward-thinking organizations that thrive
in an ever-changing landscape. We work with customers who look to the future with ambition and innovative
power.`
      },
      {
        id:2,
        head:`We dig deep, gain valuable insights, and have the courage to take decisive action. By bringing together the
right people, we challenge conventional thinking and lead change efforts. Our goal is to equip organizations
with the skills they need to achieve sustainable advantage in their industry.`
      },
      {
        id:3,
        head:`Embracing a comprehensive approach, we provide complete web application development services
encompassing design, prototyping, construction, and seamless migration to modern web architectures. Our
adept team of web design and development specialists are passionate about crafting tailored web applications.
Our focus remains on engineering secure, scalable, and responsive applications that align with your unique
needs.
`}
]
},
    {id:6,
      Name:'SaaS Product Development',
      img:'water.jpg',
      heading:'SaaS Product Development',
      content:'Using an end-to-end approach to developing products, we can build an end-to-end SaaS solution that includes web apps, APIs,storage capabilities, and data analytics',
      product:[
        {
          id:1,
          head:`Using an end-to-end approach to developing products, we can build an end-to-end SaaS solution that includes
web apps, APIs, cloud storage capabilities, and data analytics.
`}],
      saas:`High-Quality SaaS Product Development Services:`,
      develop:[
        {
          id:1,
          head:`In order to meet the complex business requirements, a quick and efficient transition to a SaaS business model
is crucial. With Sade tech's comprehensive strategy for SaaS product development, businesses can benefit from a
reliable product that is designed to scale. It is noteworthy that 90% of SaaS products developed by Sade tech have
successfully achieved their revenue benchmark.`},
]
      
},
    {id:7,
      Name:'Digital Marketing',
      img:'water.jpg',
      heading:'Digital Marketing',
      content:'Transform your brand online presence with Sade techno innovative digital marketing services',
      digital:[
        {
          id:1,
          head:`Transform your brand's online presence with Sade techno's innovative digital marketing services. With our
customized solutions, your digital footprint will soar above the competition, taking your business to new heights.
Experience the power of cutting-edge technology to supercharge your brand and accelerate your success.`
},
{
  id:2,
  head:`Through the development of captivating storylines and purposeful strategies, we take your brand and position it
in a distinctive way within the vast digital world, guaranteeing optimal visibility and impact on your desired audience.`
},
{
  id:3,
  head:`Captivate your audience with engaging content through our Content Creation and Marketing services. Our team
is adept at crafting compelling stories, articles, videos, and infographics that resonate with your audience and position
your brand as an authority in your industry.`
},
{
  id:4,
  head:`At Sade tech, we're dedicated to amplifying your online presence and achieving your business goals through
innovative digital marketing strategies. Partner with us to experience a transformational journey towards unparalleled
brand visibility, engagement, and growth.`
}
]},
    {id:8,
      Name:'IT Security Services',
      img:'water.jpg',
      heading:'IT Security Services',
      content:'Our comprehensive IT security and disaster recovery services are tailor-made to safeguard your crucial IT environmentagainst potential threats.',
      page:`Our comprehensive IT security and disaster recovery services are tailor-made to safeguard your crucial IT environment
against potential threats. Our team of experts can conduct vulnerability assessments and provide ongoing security support to ensure
your organization is protected at all times. With our top-notch solutions, you can enjoy peace of mind and focus on your core business
objectives.`}
  ] 
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element= {<Home/>}> </Route>
          <Route path='/face'element={<Face/>}></Route>
          <Route path='/about' element={<Aboutpage/>}> </Route>
          <Route path='/service'element={<Servicepage data={items}/>}> </Route>
          <Route path='/service/:serviceId'element={<Serdetail data={items} />}> </Route>
          <Route path='/tech' element={<Tech/>}></Route>
          <Route path='/contact' element={<Conpage/>}></Route>
          <Route path='/add'element={<Add/>}></Route>
          <Route path='/footer' element={<Footer/>}></Route>
          
        </Routes>
      </Router>
    </div>
  );
}
  export default App


