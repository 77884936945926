import React from 'react'

const Face = () => {
  return (
    <div>
        <div className='ela'>
        <div className='facebook'>
        <div className='book'>
            <h1 style={{color:'blue'}}>facebook</h1>
            <p>Facebook helps you connect and share with the people in your life.</p>
        
        </div>
        </div>
        
        <div className='boxtext'>
        <div className='textbox'>
            <div className='center-all'>
        <input className='blog' type='text'/><br/>
        <input className='blog' type='text'/><br/>
        <button className='log-container'>LOGIN</button>
        <p className='forget'>Forgotten Password</p>
        <div style={{display:'flex',border: '1px solid gray', margin:'10px',width:'100%'}}></div>
        <button className='green'>Create new account</button>
        </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Face