import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import About from './about';
import { Link } from 'react-router-dom';
import Contact from './contact';
import Service from './service';
import { useNavigate } from 'react-router-dom';
import { MdMargin } from 'react-icons/md';

const Footer = () => {
    const navigate =useNavigate()
    const homeopen =()=>{
        navigate('/');
        window.scrollTo(0,0);
    };
    const aboutopen = () => {
        navigate('/about');
        window.scrollTo(0, 0);
    };
    const serviceopen = () => {
        navigate('/service');
        window.scrollTo(0, 0); // Scroll to top after navigation
      };
    
      const contactopen = () => {
        navigate('/contact');
        window.scrollTo(0, 0); // Scroll to top after navigation
      };
const group=[{path:'/',open:homeopen,id:1,Name:'Home',},{path:'/about',id:2,Name:'About Us',open:aboutopen},{path:'/service',id:3,Name:'Services',open:serviceopen},{id:4,Name:'Projects'},{path:'/contact',id:5,Name:'Contact Us',open:contactopen}];

  return (
    <div>
        <div className='footer-black'>
        <div className='total-footer'>
        <div className='logo'>
        <h1 className='techno'> SADE TECHNO SOLUTIONS</h1>
        <p className='company-desc'>IT SERVICES & TECH</p>
        {/* <p className='devel-mobile'>Specializing in web and mobile app<br/> development our company takes<br/> pride in crafting top-tier software<br/> products.<br/> </p> */}
<div className='full'>
<Icon icon="ri:facebook-fill" />
        <Icon icon="ri:twitter-line" />
        <Icon icon="flowbite:linkedin-solid" />
        <Icon icon="mdi:skype" />
        </div>
</div>
<div className='link'>
<h1 className='quick'>Quick Links</h1>
{group.map((group)=>(
    <div key={group.id}>
        <div onClick={group.open}  className='link-contain'>
            <Icon icon="iconamoon:arrow-right-2-duotone" />
            {group.Name}</div>
        </div>
    ))}
    </div>
        
        <div className='contact'>
            <h1 className='user'>Contact Us</h1>
            <div className='us'>
            <div className='add'>
            <Icon icon="carbon:location-filled" />
           <p className='sup'> Address:</p>
            <p className='support'>125,Suitland Street,USA</p>
            </div>
            <div className='pho'>
            <Icon icon="ph:phone-bold" />
           <p className='sup'> Phone:</p>
            <p className='support'>+786 875 864 75</p>
            </div>
            <div className='email'>
            <Icon icon="uil:comment-alt-message" />
            <p className='sup'>E-Mail:</p>
           <p className='support'> support@globex.com</p>
           </div>
           </div>
        </div>
    </div>
    </div>
    {/* <br/><br/> */}
<div className='black'>
    <hr  style={{margin:0,border:'none',height:'2px',backgroundColor:'white'}}></hr>
    <div className='copy'>
   <p className='cpy-paste'>  Copyright 2024. All Rights Reserved. Designed by Sade Techno Solutions</p>
   <div className='right'>

    
    </div>
    </div>
    </div>
    </div>
    
    
    
    
    

    
    
  )
}

export default Footer
