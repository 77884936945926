import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'

const Add = () => {
  return (
    <div>
        <div className='sade'>
            <div className='tech'>
        <h2>SADE TECHNO SOLUTIONS</h2>
        <p className='company-desc'>IT SERVICES & TECH</p>
            </div>
        <div className='only-container'>
            <div className='iconify'>
            <Icon icon="fluent:location-28-regular" />
            </div>
            <div className='upload'>
           <h3> Address:</h3>
            <p>125, Suitland Street, USA</p>
            </div>
            </div>
            <div className='only-container'>
            <div className='iconify'>
            <Icon icon="material-symbols-light:wifi-calling-outline-rounded" />
           </div>
           <div className='upload'>
           <h3> Phone:</h3>
            <p>+ 786 875 864 75</p>
            </div>
            </div>
            <div className='only-container'>
            <div className='iconify'>
            <Icon icon="mdi:email-outline" />
            </div>
            <div className='upload'>
            <h3>E-Mail:</h3>
           <p> support@globex.com</p>
           </div>
           </div>
           </div>
    
    </div>
    
  )
}

export default Add