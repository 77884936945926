import React from 'react'
import Service from './service'
import Nav from './navbar'
import Add from './add'
import Footer from './footer'
import { Icon } from '@iconify/react/dist/iconify.js'
import Dicated from './dicated'
import { useParams } from 'react-router-dom'
import Swiper from './slider'

const Servicepage = ({data}) => {
  const {serviceId} = useParams()
  const defaultServiceId = serviceId ? parseInt(serviceId) : 1;
  const detail = data.find((service) => service.id === defaultServiceId);
  return (
    <div>
        <Nav/>
        <Swiper/>
        <div>
          <div className='plateform'>
        <div className='slice-container'>

        </div>
        <div className='detail-container'>
      <div className='left-container'>
        
         <Dicated/>
         </div>
         <div className='right-container'>
          <div className='divison'>
            <h2 style={{textDecoration:'none',color:'inherit',margin:0}}>{detail.heading}</h2>
          </div>
    
    
    <h3 style={{textDecoration:'none',color:'inherit',margin:0}}>{detail.Name}</h3>
    <p style={{margin:0}}>{detail.page}</p>
    

    {detail.points&&(    <div>
    <p style={{margin:0}} >{detail.points[0].head}</p>
    <p style={{margin:0}} >{detail.points[1].head}</p>
    <p style={{margin:0}} >{detail.points[2].head}</p>
    <p style={{fontWeight:700,margin:0}}>{detail.parahead}</p>
    <p style={{margin:0}} >{detail.approch[0].head}</p>
    <p style={{margin:0}} >{detail.approch[1].head}</p>
    <p style={{margin:0}} >{detail.approch[2].head}</p>
    </div>)}
    
    {detail.test&&(    <div>
    <p style={{margin:0}} >{detail.test[0].head}</p>
    <p style={{margin:0}} >{detail.test[1].head}</p>
    <p style={{margin:0}} >{detail.test[2].head}</p>
    </div>)}
{detail.design&&(    <div>
    <p style={{margin:0}} >{detail.design[0].head}</p>
    <p style={{margin:0}} >{detail.design[1].head}</p>
    </div>)}
    <p style={{fontWeight:700,margin:0}}>{detail.designhead}</p>
    {detail.uxui&&(    <div>
    <p style={{margin:0}} >{detail.uxui[0].head}</p>
    <p style={{margin:0}} >{detail.uxui[1].head}</p>
    </div>)}
    {detail.application&&( <div>
      <p style={{margin:0}} >{detail.application[0].head}</p>
      <p style={{margin:0}} >{detail.application[1].head}</p>
      <p style={{margin:0}} >{detail.application[2].head}</p>
</div>)}
{detail.product&&( <div>
  <p style={{margin:0}} >{detail.product[0].head}</p>
<p style={{fontWeight:700,margin:0}}>{detail.saas}</p>
<p>{detail.develop[0].head}</p>
</div>)}
{detail.digital&&( <div>
      <p style={{margin:0}} >{detail.digital[0].head}</p>
      <p style={{margin:0}} >{detail.digital[1].head}</p>
      <p style={{margin:0}} >{detail.digital[2].head}</p>
      <p style={{margin:0}} >{detail.digital[3].head}</p>
</div>)}
    </div>
    </div>
    
        </div>
    </div>
      
        <Footer/>
    </div>
  )
}

export default Servicepage