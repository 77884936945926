import React from 'react'
import Nav from './navbar'
import First from './first'
import About from './about'
import Footer from './footer'
import Service from './service'
import Contact from './contact'
import Tech from './tech'


const Home = () => {
  return (
    <div>
        <Nav />
        <First/>
        <About/>
        <Service/>
        <Contact/>
        <Tech/>
        
        <Footer/>


    </div>
  )
}

export default Home