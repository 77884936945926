import React from 'react'

const Tech = () => {
  return (

    
        <div className='up-container'>
        <div className='down'>   
        <div className='tech'>
        {/* <h3 className='intern'>TECHNOLOGY INDEX</h3> */}
        
        <p className='deliver'>We Deliver Solutions<br/> with the Goal of Trusting<br/> Workships</p>
        </div>
        <div className='web'>
        <div className='box-method'>
          <img className='bles'src='13.png'/>
            <p className='margined'>WEB</p>
            </div>
            <div className='box-method'>
              <img className='bles-all' src='14.png'/>
            <p className='margined '>ANDROID</p>
            </div>
            <div className='box-method'>
              <img className='bles-container'src='15.png'/>
           <p className='margined'>IOS</p>
           </div>
           
           <div className='box-method'>
           <img className='bles' src='11.png' />
            <p className='margined'> WEARALABLES</p>
            </div>
        </div>
        </div>
        </div>
        

    
  )
}

export default Tech